import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import CompleteOrderValidation from "./CompleteOrder.validation";

//-------------------------------------------------------------------------------------

export default function useCompleteOrderModal({
  order,
  orderId,
  onClose,
  successCallback,
}) {
  React.useEffect(() => {
    (async () => {
      if (order) {
        const tempOrder = { ...order };
        setValue(
          "actualDeliveryCoordinates",
          `${tempOrder.plannedDeliveryLatitude},${tempOrder.plannedDeliveryLongitude}`
        );
        setValue("actualDeliveryStreet", tempOrder.plannedDeliveryStreet);
        setValue("actualDeliveryCity", tempOrder.plannedDeliveryDistrict?.city);
        setValue("actualDeliveryDistrict", tempOrder.plannedDeliveryDistrict);
        setValue("actualDeliveryTime", tempOrder.plannedDeliveryTime);
      }
    })();
  }, [order]);
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(CompleteOrderValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });
  const actualDeliveryCity = form.watch("actualDeliveryCity");

  React.useEffect(() => {
    const serializedItems = order.items
      .map((item) => {
        if (item.product.serialRequired) {
          return {
            ...item,
            serialNumbers: [],
          };
        }
        return null; // or undefined
      })
      .filter(Boolean);

    setValue("serializedItems", serializedItems);
    const nonSerializedItems = order.items
      .map((item) => {
        if (
          !item.product.serialRequired &&
          item.type === enums.ProductType.NEW
        ) {
          return {
            ...item,
          };
        }
        return null;
      })
      .filter(Boolean);
    setValue("nonSerializedItems", nonSerializedItems);
  }, [order]);

  const { handleSubmit, getValues, setValue } = form;
  const submitHandler = async () => {
    let values = getValues();
    let serialNumbers = [];
    values.serializedItems.forEach((item) => {
      serialNumbers.push(item.serialNumbers);
    });

    values = {
      ...values,
      id: orderId,
      actualDeliveryLatitude:
        values.actualDeliveryCoordinates?.split(",")[0]?.trim() ?? null,
      actualDeliveryLongitude:
        values.actualDeliveryCoordinates?.split(",")[1]?.trim() ?? null,
      actualDeliveryDistrictId: values.actualDeliveryDistrict?.id,
      status: enums.OrderStatus.PENDING_STOCK_APPROVAL,
      driverShiftId: values.driverShift?.id,
      serialNumbers: serialNumbers.flat(),
      actualDeliveryTime: values.actualDeliveryTime
        ? new Date(values.actualDeliveryTime).toISOString()
        : null,
    };
    delete values.actualDeliveryCity;
    delete values.actualDeliveryDistrict;
    delete values.zone;
    delete values.driverShift;
    try {
      const updatedOrder = await sendRequest(
        api.orders.complete(orderId, values)
      );
      toast.success(`Order is now pending stock approval`);
      successCallback(updatedOrder);
      onClose();
    } catch {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    actualDeliveryCity,
    submitHandler,
    onError,
    handleSubmit,
  };
}
