import React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export const useDriverView = ({ path }) => {
  const [sendRequest, isGetDriverLoading] = useHttpClient();

  const [driver, setDriver] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [currentTab, setCurrentTab] = React.useState(0);

  const [isOpen, setIsOpen] = React.useState(false);

  const history = useHistory();

  const dataResource = React.useMemo(() => api.drivers, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const getDriver = React.useCallback(async () => {
    setLoading(true);
    const id = window.location.pathname.split("/").pop().trim();
    if (id && !isNaN(id)) {
      try {
        const response = await sendRequest(dataResource.getOne(id));
        setDriver({ quotations: [], ...response });
      } catch (err) {
        setLoading(false);
        toast.error("An error has occurred");
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } else {
      toast.error("An error has occurred");
      history.push({
        pathname: `/admin/${path}`,
      });
    }
    setLoading(false);
  }, [dataResource]);

  React.useEffect(() => {
    getDriver();
  }, [getDriver]);

  return {
    isGetDriverLoading,
    driver,
    currentTab,
    isOpen,
    setDriver,
    setCurrentTab,
    getDriver,
    onClose,
    loading,
  };
};
