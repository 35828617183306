import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { displayCustomerPhone, getDateString } from "utils/index";
import OrderStatusButtons from "views/Orders/components/OrderStatusButtons/OrderStatusButtons";
import useOrdersList from "./OrdersList.hook";
import styles from "./OrdersList.styles";
import CustomerInfoModal from "./CustomerInfoModal/CustomerInfoModal";
import api from "services/api";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrdersList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource } = props;

  const filterInputs = useMemo(
    () => [
      {
        name: "status",
        label: "Status",
        type: "select",
        options: {
          data: Object.keys(translations[i18n.language].orderStatus).map(
            (key) => ({
              value: key,
              name: translations["en"].orderStatus[key],
            })
          ),
          addAllChoice: true,
        },
      },
      {
        name: "plannedDeliveryTime",
        label: "Planned Delivery Date",
        type: "date",
      },
      {
        name: "createdAt.from",
        label: "Created At (from)",
        type: "date",
      },
      {
        name: "createdAt.to",
        label: "Created At (to)",
        type: "date",
      },
      {
        name: "followUpDate",
        label: "Follow Up Date",
        type: "date",
      },
      {
        name: "assignedDriverId",
        label: "Driver Name",
        type: "autocomplete",
        options: {
          getData: api.drivers.getAll,
          getOptionLabel: (option) => option?.name,
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
          multiple: true,
        },
      },
      {
        name: "createdByIds",
        label: "Created By",
        type: "autocomplete",
        options: {
          getData: api.users.getAll,
          getOptionLabel: (option) => option?.name,
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
          multiple: true,
        },
      },
    ],
    []
  );

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    isCustomerInfoModalOpen,
    selectedCustomer,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    updateOrderState,
    setIsCustomerInfoModalOpen,
    setSelectedCustomer,
  } = useOrdersList({
    resource,
    filterInputs,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Order No.",
        dataIndex: "orderNo",
        width: 110,
        fixed: "left",
        canBeHighlighted: true,
        render: (data) => `#${data}`,
      },
      {
        title: "Customer",
        dataIndex: ["car", "owner", "name"],
        width: 150,
        render: (_, record) =>
          record.car?.owner ? (
            <span
              href={`/admin/customers/${record.car?.owner?.id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              onClick={() => {
                setSelectedCustomer(record.car?.owner);
                setIsCustomerInfoModalOpen(true);
              }}
            >
              <Highlighter
                highlightClassName={classes.highlightedText}
                searchWords={[highlightedText]}
                autoEscape={true}
                textToHighlight={record.car?.owner?.name ?? ""}
              />
            </span>
          ) : (
            "-"
          ),
      },
      {
        title: "Phone",
        render: (_, record) => {
          return displayCustomerPhone(record.car?.owner);
        },
        width: 200,
        canBeHighlighted: true,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
        width: 200,
      },
      {
        title: "Created By",
        dataIndex: ["createdBy", "name"],
      },
      {
        title: "Planned Delivery Date",
        dataIndex: "plannedDeliveryTime",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
        width: 200,
      },

      {
        title: "Delivery Type",
        dataIndex: "deliveryType",
        getData: (data) =>
          data ? translations[i18n.language].deliveryType[data] : "-",
        width: 100,
      },

      {
        title: "Driver Name / Phone",
        dataIndex: "assignedDriver",
        render: (_, record) => {
          let driver = "";
          if (record.assignedDriver) {
            driver += record.assignedDriver.name;
            driver += ` / ${record.assignedDriver.phone}`;
          }
          return driver ? driver : "-";
        },

        width: 200,
      },
      {
        title: "Status",
        dataIndex: "status",
        getData: (data) => {
          if (data) return translations["en"].orderStatus[data];
          else return "-";
        },
        width: 100,
      },
      {
        title: "Follow Up Date",
        dataIndex: "followUpDate",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
        width: 200,
      },

      {
        title: "",
        render: (_, record) => {
          return (
            <div style={{ display: "flex", width: "fit-content" }}>
              <OrderStatusButtons
                order={record}
                orderId={record?.id}
                status={record?.status}
                successCallback={updateOrderState}
              />
              <CheckPermission action={enums.Action.READ}>
                <Link to={`/admin/orders/${record?.id}`}>
                  <Button
                    color="white"
                    textColor="primary"
                    className={classes.viewButton}
                  >
                    <NavigateNextIcon className={classes.nextIcon} />
                  </Button>
                </Link>
              </CheckPermission>
            </div>
          );
        },
        width: 270,
        fixed: "right",
      },
    ],
    [listData, updateOrderState]
  );

  return (
    <div>
      {isCustomerInfoModalOpen && (
        <CustomerInfoModal
          isOpen={isCustomerInfoModalOpen}
          onClose={() => setIsCustomerInfoModalOpen(false)}
          customer={selectedCustomer}
        />
      )}
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <CheckPermission action={enums.Action.CREATE}>
            <Link to={`/admin/orders/create`}>
              <Button create color="white" />
            </Link>
          </CheckPermission>
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Orders Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

OrdersList.propTypes = {
  resource: PropTypes.string,
};
