import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import Tabs from "components/Tabs/Tabs";
import { DriverStatusColors } from "constants/colors";
import translations from "constants/translations";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDriverView } from "./DriverView.hook";
import styles from "./DriverView.styles";
import DriverDetails from "./components/Tabs/DriverDetails/DriverDetails";
import { getDateString } from "utils/index";
import LiveLocation from "./components/Tabs/LiveLocation/LiveLocation";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DriverView({ path }) {
  const classes = useStyles();

  const {
    driver,
    isGetDriverLoading,
    currentTab,
    setCurrentTab,
    getDriver,
    loading,
  } = useDriverView({ path });

  const { i18n } = useTranslation();

  const tabs = useMemo(
    () => [
      {
        name: "Details",
        component: <DriverDetails driver={driver} getDriver={getDriver} />,
      },
      {
        name: "Live Location",
        component: <LiveLocation driver={driver} />,
      },
    ],
    [driver]
  );

  return (
    <div>
      <div className={classes.upperContainer}>
        <Breadcrumbs />
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card raised>
            <CardBody>
              <Loading loading={loading} style={{ height: "70vh" }}>
                {driver ? (
                  <React.Fragment>
                    <div className={classes.pageHeader}>
                      <div className={classes.infoContainer}>
                        <div className={classes.infoWithIcon}>
                          <PersonIcon className={classes.personIcon} />
                          <div className={classes.headerInfoContainer}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {`${driver?.name}`} - &nbsp;
                              <div
                                style={{
                                  color: DriverStatusColors[driver?.status],
                                }}
                              >
                                {
                                  translations[i18n.language].driverStatus[
                                    driver?.status
                                  ]
                                }
                              </div>
                            </div>

                            <div className={classes.subtitle}>
                              {`Phone : ${driver?.phone} `}
                            </div>
                            <div className={classes.subtitle}>
                              {driver?.joiningDate
                                ? `Date: ${getDateString(driver?.joiningDate)}`
                                : "Date: N/A"}
                            </div>

                            <div className={classes.subtitle}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Tabs
                      buttonsContainerClasses={classes.tabsContainer}
                      tabs={tabs}
                      value={currentTab}
                      onChange={setCurrentTab}
                    />
                  </React.Fragment>
                ) : (
                  <div>No available data</div>
                )}
              </Loading>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
