import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AddStockTransferValidation from "./AddStockTransferModal.validation";
import { useHistory } from "react-router";

//-------------------------------------------------------------------------------------

export default function useAddStockTransferModal({
  onClose,
  successCallback,
  selectedTruck,
  driverShiftId,
  getData,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const dataResource = React.useMemo(() => api.stockItemsTransfers, []);

  const history = useHistory();

  const resolver = useYupValidationResolver(AddStockTransferValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      amount: 1,
    },
  });

  const { handleSubmit, getValues, setValue } = form;
  const submitHandler = async () => {
    let values = getValues();

    if (
      values.transferType?.id ===
      enums.ValidStartOfShiftStockTransfersTypes.FROM_TRUCK_TO_WAREHOUSE
    ) {
      values = {
        fromId: selectedTruck?.storage?.transferPartyId,
        toId: values?.to?.storage?.transferPartyId,
        productId: values.product?.id,
        driverShiftId: driverShiftId,

        amount: values.amount,
      };
    } else {
      values = {
        fromId: values.from?.storage?.transferPartyId,
        toId: selectedTruck?.storage?.transferPartyId,
        productId: values.product?.id,
        driverShiftId: driverShiftId,

        amount: values.amount,
      };
    }

    try {
      const newTransfer = await sendRequest(dataResource.create(values));
      if (newTransfer) {
        sendRequest(api.stockItemsTransfers.approveTransfer(newTransfer.id));
      }
      onClose();
      getData();

      // successCallback(newTransfer);
      toast.success(`Transfer Request added successfully`);
    } catch (err) {
      console.log(err);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
