import { GOOGLE_MAP_URL, MAP_SETTINGS } from "constants/maps";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import OutsideClickHandler from "react-outside-click-handler";
import mapStyles from "./mapStyles.json";
import React from "react";

const {
  DEFAULT_ZOOM,
  DEFAULT_CENTER,
  DEFAULT_MAP_OPTIONS,
  PIXEL_OFFSET,
  MARKER_HEIGHT,
} = MAP_SETTINGS;

const MapContainerComponent = (props) => {
  const { driverLocation } = props;

  const [isClickOutsideDisabled, setIsClickOutsideDisabled] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const mapRef = useRef(null);
  const [mapCenter, setMapCenter] = useState({
    lat: parseFloat(driverLocation.latitude),
    lng: parseFloat(driverLocation.longitude),
  });

  useEffect(() => {
    if (driverLocation) {
      const newCenter = {
        lat: parseFloat(driverLocation.latitude),
        lng: parseFloat(driverLocation.longitude),
      };
      setMapCenter(newCenter);
      // if (mapRef.current) {
      //   mapRef.current.panTo(newCenter);
      // }
    }
  }, [driverLocation]);

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={DEFAULT_ZOOM}
      center={mapCenter}
      defaultOptions={{ ...DEFAULT_MAP_OPTIONS, styles: mapStyles }}
      onDragStart={() => setIsClickOutsideDisabled(true)}
      onDragEnd={() => setIsClickOutsideDisabled(false)}
    >
      {driverLocation && (
        <Marker
          position={{
            lat: parseFloat(driverLocation.latitude),
            lng: parseFloat(driverLocation.longitude),
          }}
          icon={{
            url: require(`assets/img/icons/marker.png`),
            scaledSize: new window.google.maps.Size(
              MARKER_HEIGHT * 0.6,
              MARKER_HEIGHT
            ),
          }}
          onClick={() => {
            setSelectedLocation(null);
          }}
        />
      )}

      {selectedLocation?.info && (
        <InfoWindow
          position={{
            lat: selectedLocation.info.latitude,
            lng: selectedLocation.info.longitude,
          }}
          options={{
            pixelOffset: new window.google.maps.Size(
              PIXEL_OFFSET.MARKER.X,
              PIXEL_OFFSET.MARKER.Y
            ),
          }}
          onCloseClick={() => setSelectedLocation(null)}
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              setSelectedLocation(null);
            }}
            disabled={isClickOutsideDisabled}
          />
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

MapContainerComponent.propTypes = {
  driverLocation: PropTypes.object,
};

const MapContainer = withScriptjs(withGoogleMap(MapContainerComponent));

const Map = (props) => (
  <MapContainer
    googleMapURL={GOOGLE_MAP_URL}
    loadingElement={<div style={{ height: "100%" }} />}
    containerElement={<div style={{ height: "100%" }} />}
    mapElement={
      <div
        style={{ height: "100%", borderRadius: 6, border: "1px solid #ddd" }}
      />
    }
    {...props}
  />
);

export default Map;
