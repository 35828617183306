import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getDateString } from "utils/index";
import DriversFormModal from "../DriversFormModal/DriversFormModal";
import useDriversList from "./DriversList.hook";
import styles from "./DriversList.styles";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DriversList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource } = props;
  const path = "driver";

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    isAssignModalOpen,
    editedDriver,
    onAssignModalClose,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    handleAssignItem,
    handleRemoveItem,
  } = useDriversList({
    resource,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: "name",
        title: "Name",
        canBeHighlighted: true,
      },
      {
        dataIndex: "phone",
        title: "Phone",
        canBeHighlighted: true,
      },
      {
        dataIndex: "nationalId",
        title: "National ID",
        canBeHighlighted: true,
      },
      {
        dataIndex: "joiningDate",
        title: "Joining Date",
        render: (data) => (data ? getDateString(data) : "-"),
      },
      {
        dataIndex: "leaveDate",
        title: "Leave Date",
        render: (data) => (data ? getDateString(data) : "-"),
      },
      {
        dataIndex: "status",
        title: "Status",
        getData: (data) => {
          if (data) return translations["en"].driverStatus[data];
          else return "-";
        },
      },
      {
        dataIndex: ["defaultTruck", "truckNumber"],
        title: "Default Truck",
      },

      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              {/* <CheckPermission action={enums.Action.UPDATE}>
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleAssignItem(record)}
                />
              </CheckPermission> */}

              <CheckPermission action={enums.Action.READ}>
                <Link to={`/admin/drivers/${record?.id}`}>
                  <Button
                    color="white"
                    textColor="primary"
                    className={classes.viewButton}
                  >
                    <NavigateNextIcon className={classes.nextIcon} />
                  </Button>
                </Link>
              </CheckPermission>
              {/* <CheckPermission action={enums.Action.DELETE}>
                <ConfirmationButton
                  name="this item"
                  entityName="Item"
                  onConfirm={() => handleRemoveItem(record)}
                  // onSuccess={() => onRemoveModalClose(record)}
                  buttonProps={{
                    justIcon: true,
                  }}
                />
              </CheckPermission> */}
            </div>
          );
        },
      },
    ],
    [listData]
  );

  return (
    <div>
      {isAssignModalOpen && (
        <DriversFormModal
          isOpen={isAssignModalOpen}
          onClose={onAssignModalClose}
          create={!editedDriver}
          edit={!!editedDriver}
          driver={editedDriver}
          path={path}
        />
      )}
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <Button create color="white" onClick={() => handleAssignItem()} />
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Drivers Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

DriversList.propTypes = {
  resource: PropTypes.string,
};
