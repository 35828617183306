import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import styles from "./LiveLocation.styles";
import Map from "components/Map/Map";
import React, { useEffect } from "react";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button";
import CachedIcon from "@material-ui/icons/Cached";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import { useLiveLocation } from "./LiveLocation.hook";
import { get } from "lodash";
import { getDateString } from "utils/index";
//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function LiveLocation(props) {
  const classes = useStyles();

  const { driver } = props;
  const {
    isGetDriverLiveLocationLoading,
    driverLiveLocation,
    getDriverLiveLocation,
  } = useLiveLocation({ driver });
  const { i18n } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>Driver live location</h3>
        <InfoWithLabelGridItem
          label="Last Recorded Location"
          // type={enums.InfoType.DATETIME}
          info={
            driverLiveLocation
              ? getDateString(driverLiveLocation?.timestamp, "en-EG", true, {
                  includeSeconds: true,
                })
              : "-"
          }
        />
        <Button
          color="success"
          onClick={getDriverLiveLocation}
          className={classes.addButton}
        >
          <CachedIcon className={classes.addIcon} />
          Refresh Location
        </Button>
      </div>
      <Loading
        loading={isGetDriverLiveLocationLoading}
        style={{ height: "75vh" }}
      >
        {driverLiveLocation && !isGetDriverLiveLocationLoading ? (
          <div className={classes.section}>
            <Map driverLocation={driverLiveLocation} />
          </div>
        ) : (
          <div className={classes.noLocationContainer}>
            <h3 className={classes.noLocation}>
              No location for driver {driver.name}
            </h3>
          </div>
        )}
      </Loading>
    </div>
  );
}
