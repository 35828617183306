import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import DriversFormModal from "views/StockManagement/Drivers/DriversFormModal/DriversFormModal";
import styles from "./DriverDetails.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DriverDetails(props) {
  const classes = useStyles();

  const { driver, getDriver } = props;

  const { i18n } = useTranslation();

  // const { userData } = React.useContext(AuthContext);

  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const onAssignModalClose = () => {
    setIsAssignModalOpen(false);
    getDriver();
  };

  return (
    <div className={classes.root}>
      {isAssignModalOpen && (
        <DriversFormModal
          isOpen={isAssignModalOpen}
          onClose={onAssignModalClose}
          create={!driver}
          edit={!!driver}
          driver={driver}
          // path={path}
        />
      )}
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>Driver details</h3>
        <Button
          color="primary"
          edit
          onClick={() => setIsAssignModalOpen(true)}
          className={classes.addButton}
        ></Button>
      </div>
      <div className={classes.section}>
        <GridContainer>
          <InfoWithLabelGridItem
            label="Driver Status"
            info={translations[i18n.language].driverStatus[driver?.status]}
          />

          <InfoWithLabelGridItem label="Driver" info={driver?.name} />
          <InfoWithLabelGridItem
            label="National Id"
            info={driver?.nationalId}
          />
          <InfoWithLabelGridItem label="Phone Number" info={driver?.phone} />
          <InfoWithLabelGridItem
            label="Truck Number"
            info={driver?.defaultTruck?.truckNumber}
          />

          <InfoWithLabelGridItem
            label="Joining Date"
            type={enums.InfoType.DATETIME}
            info={driver?.joiningDate}
          />
          <InfoWithLabelGridItem
            label="Leave Date"
            type={enums.InfoType.DATETIME}
            info={driver?.leaveDate}
          />
          <InfoWithLabelGridItem
            label="Driver License"
            info={driver?.drivingLicenseDocumentUrl}
            type={enums.InfoType.FILE}
          />
        </GridContainer>
      </div>
    </div>
  );
}
