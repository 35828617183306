import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import OrderItemModal from "./components/OrderItemModal/OrderItemModal";
import useOrderItems from "./OrderItems.hook";
import styles from "./OrderItems.styles";
import { getDateString, isOrderEditable } from "utils/index";
import { AuthContext } from "shared/context/auth-context";
import ModalImage from "react-modal-image";
import { useTranslation } from "react-i18next";
import translations from "constants/translations/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderItems() {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedItem,
    orderItems,
    onAssignModalClose,
    handleAssignItem,
    handleRemoveItem,
    onRemoveModalClose,
    orderWarranties,
    isLoadingGetWarranties,
  } = useOrderItems();

  const { userData } = React.useContext(AuthContext);

  const warrantiesColumns = React.useMemo(
    () => [
      {
        dataIndex: ["serialNumber"],
        title: "Serial Number",
        canBeHighlighted: true,
      },

      {
        dataIndex: ["order", "car", "owner", "name"],
        title: "Customer",
        canBeHighlighted: true,
      },
      {
        dataIndex: ["status"],
        title: "Status",
        getData: (data) => {
          if (data) return translations["en"].warrantyStatus[data];
          else return "-";
        },
      },
      {
        dataIndex: ["activationDate"],
        title: "Activation Date",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
      },
      {
        dataIndex: ["driver", "name"],
        title: "Driver",
      },
    ],
    [orderWarranties]
  );

  const columns = React.useMemo(
    () => [
      {
        title: "SAP Code",
        dataIndex: ["product", "sapCode"],
      },
      {
        title: "Description",
        dataIndex: ["product", "description"],
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },

      {
        title: "Promo Code",
        render: (_, record) => {
          return (
            <div>
              {record.promoCodeType === enums.PromoCodeType.PERCENTAGE ? (
                <div>
                  {record.promoCode.code}
                  {` (${record.promoCode.amount}%)`}
                </div>
              ) : record.promoCodeType === enums.PromoCodeType.FIXED ? (
                <div>
                  {record.promoCode.code}
                  {` (-${record.promoCode.amount} EGP)`}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },

      {
        title: "Total Price",
        render: (_, record) => {
          return (
            <div>
              {record.totalPriceBeforeDiscount !== record.totalPrice ? (
                <div>
                  <strike style={{ color: "red" }}>
                    {record.totalPriceBeforeDiscount}
                  </strike>
                  <span> {record.totalPrice}</span>
                </div>
              ) : (
                <div>{record.totalPrice}</div>
              )}
            </div>
          );
        },
      },
      {
        title: "Image",
        render: (_, record) => {
          return record?.photoUrl ? (
            <div>
              <ModalImage
                small={record?.photoUrl}
                large={record?.photoUrl}
                alt="..."
                className={classes.image}
                showRotate
              />
            </div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.UPDATE}>
                  <Button
                    justIcon
                    edit
                    color="white"
                    textColor="success"
                    onClick={() => handleAssignItem(record)}
                  />
                </CheckPermission>
              )}
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.DELETE}>
                  <ConfirmationButton
                    name="this item"
                    entityName="Item"
                    onConfirm={() => handleRemoveItem(record)}
                    onSuccess={() => onRemoveModalClose(record)}
                    buttonProps={{
                      justIcon: true,
                    }}
                  />
                </CheckPermission>
              )}
            </div>
          );
        },
      },
    ],
    [order]
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        {isAssignModalOpen && (
          <OrderItemModal
            isOpen={isAssignModalOpen}
            onClose={onAssignModalClose}
            create={!editedItem}
            edit={!!editedItem}
            item={editedItem}
          />
        )}
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Order Items</h3>
            {isOrderEditable(order, userData) && (
              <CheckPermission action={enums.Action.CREATE}>
                <Button
                  onClick={() => handleAssignItem()}
                  className={classes.addButton}
                  color="success"
                >
                  <AddIcon className={classes.addIcon} />
                  Add Order Item
                </Button>
              </CheckPermission>
            )}
          </div>
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={orderItems}
              loading={isGetOrderLoading}
              addIndexColumn
            />
          </div>
          {orderWarranties && orderWarranties.length > 0 && (
            <React.Fragment>
              <div className={classes.warrantiesTableHeader}>
                <h3 className={classes.header}>Warranties</h3>
              </div>
              <div className={classes.tableContainer}>
                <CustomTable
                  columns={warrantiesColumns}
                  data={orderWarranties}
                  loading={isLoadingGetWarranties}
                  addIndexColumn
                />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    </div>
  );
}
