import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import { orderStatusColors } from "constants/colors";
import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDateString, isOrderEditable } from "utils/index";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import OrderDetailsForm from "../../OrderDetailsForm/OrderDetailsForm";
import styles from "./OrderDetails.styles";
import { AuthContext } from "shared/context/auth-context";
import EditMatchingInfoModal from "./components/EditMatchingInfo/EditMatchingInfoModal";
import CachedIcon from "@material-ui/icons/Cached";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderDetails() {
  const classes = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);

  const { i18n } = useTranslation();

  const { order, setOrder } = React.useContext(OrderContext);

  const { userData } = React.useContext(AuthContext);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const updateOrderMatchingInfo = (updatedOrder) => {
    setOrder(updatedOrder);
    setIsAssignOrderModalOpen(false);
  };
  const statusHistoryData = React.useMemo(() => {
    const data = [];
    if (!order?.statusHistory?.length) {
      data.push({
        status: order?.status,
        createdBy: order?.createdBy,
        createdAt: order?.createdAt,
      });
    }

    for (let i = 0; i < order?.statusHistory.length; i++) {
      if (i === 0) {
        data.push({
          status: order?.statusHistory[i].status,
          createdBy: order?.createdBy,
          createdAt: order?.createdAt,
        });
      } else {
        data.push({
          status: order?.statusHistory[i].status,
          createdBy: order?.statusHistory[i].revisedBy,
          createdAt: order?.statusHistory[i].timestamp,
        });
      }
    }
    return data;
  }, [order]);
  const orderStatusHistoryColumns = React.useMemo(
    () => [
      {
        title: "Status",
        dataIndex: "status",
        getData: (status) => {
          return (
            <div
              style={{
                color: orderStatusColors[status],
                fontWeight: 600,
              }}
            >
              {translations[i18n.language].orderStatus[status]}
            </div>
          );
        },
      },
      {
        title: "Updated By",
        dataIndex: ["createdBy", "name"],
      },
      {
        title: "Updated At",
        dataIndex: "createdAt",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : ""),
      },
    ],
    [statusHistoryData]
  );

  const matchingDetailsEditableStatuses = [
    enums.OrderStatus.ASSIGNED,
    enums.OrderStatus.IN_PROGRESS,
  ];

  const [isAssignOrderModalOpen, setIsAssignOrderModalOpen] = React.useState(
    false
  );
  return isEditing ? (
    <OrderDetailsForm edit={true} setIsEditing={setIsEditing} />
  ) : (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>Order details</h3>
        <CheckPermission action={enums.Action.UPDATE}>
          {isOrderEditable(order, userData) && (
            <Button onClick={handleEdit} color="primary" edit={true} />
          )}
        </CheckPermission>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="leadInfo"
          titleComponent={<h3 style={{ margin: 0 }}>Lead Info</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              label="Order Status"
              info={translations[i18n.language].orderStatus[order?.status]}
            />
            <InfoWithLabelGridItem
              label="Customer"
              info={order?.car?.owner?.name}
            />
            <InfoWithLabelGridItem
              label="Car"
              info={
                order?.car
                  ? `${order?.car?.model?.maker?.name + " - " || ""}${
                      order?.car?.model?.name + " - " || ""
                    } ${order?.car?.model?.year || ""}`
                  : "-"
              }
            />
            <InfoWithLabelGridItem
              label="Channel"
              info={translations[i18n.language].customerChannel[order?.channel]}
            />
            <InfoWithLabelGridItem
              label="Lead Follow Up"
              info={
                translations[i18n.language].leadFollowUp[order?.leadFollowUp]
              }
            />
            <InfoWithLabelGridItem
              label="Follow Up Date"
              type={enums.InfoType.DATETIME}
              info={order?.followUpDate}
            />
            <InfoWithLabelGridItem
              label="Lead Loss Reason"
              info={order?.leadLossReason?.name}
            />
            <InfoWithLabelGridItem
              label="Notes"
              type={enums.InfoType.TEXT}
              info={order?.notes}
            />
          </GridContainer>
        </CollapseContainer>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="orderInfo"
          titleComponent={<h3 style={{ margin: 0 }}>Order Info</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              label="Delivery Type"
              info={
                translations[i18n.language].deliveryType[order?.deliveryType]
              }
            />
            <InfoWithLabelGridItem
              label="Payment Method"
              info={order?.paymentMethod?.name}
            />
            <InfoWithLabelGridItem
              label="Zone Delivery Fees"
              info={
                order?.zone
                  ? `${order?.deliveryFees} EGP (${order?.zone?.name})`
                  : "-"
              }
            />
            <InfoWithLabelGridItem
              label="Desired Delivery Date/time"
              info={order?.desiredDeliveryTime}
              type={enums.InfoType.DATETIME}
            />
            <InfoWithLabelGridItem
              label="Planned Delivery Date/time"
              info={order?.plannedDeliveryTime}
              type={enums.InfoType.DATETIME}
            />
            <InfoWithLabelGridItem
              label="Planned Delivery Coordinates"
              info={
                order?.plannedDeliveryLongitude
                  ? `${order?.plannedDeliveryLatitude},${order?.plannedDeliveryLongitude}`
                  : ""
              }
            />
            <InfoWithLabelGridItem
              label="Planned Delivery Street"
              info={order?.plannedDeliveryStreet}
            />
            <InfoWithLabelGridItem
              label="Planned Delivery District"
              info={order?.plannedDeliveryDistrict?.name}
            />
            <InfoWithLabelGridItem
              label="Planned Delivery City"
              info={order?.plannedDeliveryDistrict?.city?.name}
            />
          </GridContainer>
        </CollapseContainer>
      </div>

      <div style={{ marginBottom: -43 }} className={classes.headerContainer}>
        <h3 style={{ marginLeft: 20 }}>Matching Info</h3>
        {matchingDetailsEditableStatuses.includes(order?.status) && (
          <Button
            onClick={() => {
              setIsAssignOrderModalOpen(true);
            }}
            color="primary"
            className={classes.changeMatchingInfoButton}
            style={{ marginRight: 60 }}
          >
            <CachedIcon className={classes.changeIcon} /> Change Matching Info
          </Button>
        )}
      </div>

      <div className={classes.section}>
        <CollapseContainer
          name="matchingInfo"
          titleComponent={<div />}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              label="Assigned Driver"
              info={order?.assignedDriver?.name}
            />
            <InfoWithLabelGridItem
              label="Assigned Truck"
              info={order?.assignedTruck?.truckNumber}
            />
          </GridContainer>
        </CollapseContainer>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="actualDelivery"
          titleComponent={<h3 style={{ margin: 0 }}>Delivery Info</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              label="Actual Delivery Date/time"
              info={order?.actualDeliveryTime}
              type={enums.InfoType.DATETIME}
            />
            <InfoWithLabelGridItem
              label="Actual Delivery Coordinates"
              info={
                order?.actualDeliveryLatitude
                  ? `${order?.actualDeliveryLatitude},${order?.actualDeliveryLongitude}`
                  : ""
              }
            />
            <InfoWithLabelGridItem
              label="Actual Delivery Street"
              info={order?.actualDeliveryStreet}
            />
            <InfoWithLabelGridItem
              label="Actual Delivery District"
              info={order?.actualDeliveryDistrict?.name}
            />
            <InfoWithLabelGridItem
              label="Actual Delivery City"
              info={order?.actualDeliveryDistrict?.city?.name}
            />
            <InfoWithLabelGridItem
              label="Customer Rating"
              info={order?.orderRating}
            />
          </GridContainer>
        </CollapseContainer>
      </div>

      {isAssignOrderModalOpen && (
        <EditMatchingInfoModal
          isOpen={isAssignOrderModalOpen}
          onClose={() => setIsAssignOrderModalOpen(false)}
          // orderId={order?.id}
          order={order}
          key={isAssignOrderModalOpen}
          successCallback={updateOrderMatchingInfo}
        />
      )}
      <div className={classes.section}>
        <CollapseContainer
          name="orderStatusHistory"
          titleComponent={<h3 style={{ margin: 0 }}>Order Status History</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <div className={classes.tableContainer}>
              <CustomTable
                columns={orderStatusHistoryColumns}
                data={statusHistoryData || []}
                addIndexColumn
              />
            </div>
          </GridContainer>
        </CollapseContainer>
      </div>
    </div>
  );
}
