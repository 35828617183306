import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TooltipWithIcon from "components/TooltipWithIcon/TooltipWithIcon";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useReviewTruckInventoryAndEndOfShiftTransfers from "./ReviewTruckInventoryAndEndOfShiftTransfers.hook";
import styles from "./ReviewTruckInventoryAndEndOfShiftTransfers.styles";
import StockTransfers from "./components/StockTransfers/StockTransfers";
import CachedIcon from "@material-ui/icons/Cached";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ReviewTruckInventoryAndEndOfShiftTransfers(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource, selectedTruck, driverShift } = props;

  const {
    currentPage,
    listData,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    handleChange,
    handleSubmit,
    setShouldGetTransfers,
    shouldGetTransfers,
    getData,
  } = useReviewTruckInventoryAndEndOfShiftTransfers({
    resource,
    // filterInputs,
    selectedTruck,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["sapCode"],
        title: "SAP Code",
        canBeHighlighted: true,
      },
      {
        dataIndex: ["manufacturerName"],
        title: "Manufacturer",
      },
      {
        dataIndex: ["size"],
        title: "Size",
      },
      { dataIndex: ["stockItemsCount"], title: "All Stock Count" },
      {
        dataIndex: ["stockItemsOnHoldCount"],
        width: 120,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Stock on Hold</span>
              <TooltipWithIcon
                title="Total stock on hold by a pending transfer 'FROM' a storage"
                marginLeft={5}
              />
            </div>
          );
        },
      },
      // {
      //   dataIndex: ["totalOrderItems"],
      //   width: 110,
      //   title: () => {
      //     return (
      //       <div className={classes.tableHeaderContainer}>
      //         <span>Open orders</span>
      //         <TooltipWithIcon
      //           title="Stock on hold across the system by open orders (orders with status 'planned', 'assigned', or 'in progress')"
      //           marginLeft={5}
      //         />
      //       </div>
      //     );
      //   },
      // },
      {
        dataIndex: ["availableStock"],
        width: 120,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Available stock</span>
              <TooltipWithIcon
                title="All stock - stock on hold - open orders"
                marginLeft={5}
              />
            </div>
          );
        },
      },
    ],
    [listData]
  );

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            title={`Inventory for ${driverShift?.assignedTruck?.truckNumber} `}
            addIndexColumn
            size="middle"
            fixIndexColumn
            onChange={handleChange}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <StockTransfers
            shouldGetTransfers={shouldGetTransfers}
            setShouldGetTransfers={setShouldGetTransfers}
            selectedTruck={selectedTruck}
            title={`End of shift stock transfers for ${driverShift?.assignedTruck?.truckNumber} `}
            driverShift={driverShift}
          ></StockTransfers>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ReviewTruckInventoryAndEndOfShiftTransfers.propTypes = {
  resource: PropTypes.string,
};
