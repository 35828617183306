import * as yup from "yup";

//----------------------------------------------------------

export default (driver) => {
  return yup.object().shape({
    name: yup.mixed().required("Required"),
    phone: yup.mixed().required("Required"),
    nationalId: yup.mixed().required("Required"),
    status: yup.mixed().required("Required"),
    // if driver is null then the password is required
    password: yup
      .string()
      .min(8, "Must be 8 characters or more")
      .test("password", "Must be 8 characters or more", (value) => {
        if (driver) {
          return true;
        } else {
          return value && value.length >= 8;
        }
      }),
  });
};
