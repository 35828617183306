import EventSource from "eventsource";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export const useLiveLocation = ({ driver }) => {
  const [driverLiveLocation, setDriverLiveLocation] = React.useState(null);

  const [
    sendGetDriverLiveLocationRequest,
    isGetDriverLiveLocationLoading,
  ] = useHttpClient();

  const history = useHistory();

  const getDriverLiveLocation = useCallback(async () => {
    try {
      const driverId = driver?.id;
      const response = await sendGetDriverLiveLocationRequest(
        api.drivers.getDriverCurrentLocation(driverId)
      );
      if (!response) {
        return;
      }

      const latitude = response.latitude;
      const longitude = response.longitude;
      const timestamp = response.timestamp;
      setDriverLiveLocation({
        latitude,
        longitude,
        timestamp,
      });
    } catch (err) {
      toast.error("An error has occurred");
    }
  }, [sendGetDriverLiveLocationRequest]);

  useEffect(() => {
    const driverId = driver?.id;
    const { url, token } = api.drivers.getDriverLocationStream(driverId);
    const eventSource = new EventSource(url, {
      headers: {
        Authorization: `${token}`,
      },
    });

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { latitude, longitude, timestamp } = data;
      setDriverLiveLocation({
        latitude,
        longitude,
        timestamp,
      });
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
    };

    // Fetch initial location
    getDriverLiveLocation();

    return () => {
      eventSource.close();
    };
  }, [getDriverLiveLocation]);

  return {
    driver,
    getDriverLiveLocation,
    isGetDriverLiveLocationLoading,
    driverLiveLocation,
  };
};
