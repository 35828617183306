import enums from "enums/index";
import api from "services/api";
import DriverView from "views/StockManagement/Drivers/DriverView/DriverView";
import DriversList from "views/StockManagement/Drivers/DriversList/DriversList";
import React from "react";

// appear in breadcrumbs and page headers
const listPageName = "Drivers";
const viewPageName = "Driver Details";

// appears on alerts; example: Entity created successfully
const entityName = "Driver";

const layout = "/admin";

const path = "drivers";

const dataResource = api.drivers;

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_DRIVERS,
];

// appear in breadcrumbs and page headers

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "man",
  render: () => <DriversList />,
  action: "read",
  layout: layout,
  superAdmin: true,
  children: true,
  resource,
  views: [
    {
      path: `/${path}/:id`,
      render: () => <DriverView path={path} />,
      layout: layout,
      // randomKey: true,
      action: "read",
      getName: async (id) => {
        try {
          const data = (await dataResource.getOne(id)).data;
          return `${data.name}`;
        } catch {
          return viewPageName;
        }
      },
      resource,
    },
  ],
};
