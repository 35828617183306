import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import ProcessOrderValidation from "./ProcessOrder.validation";

//-------------------------------------------------------------------------------------

export default function useProcessOrderModal({
  orderId,
  onClose,
  successCallback,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(ProcessOrderValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;
  const submitHandler = async () => {
    let values = getValues();

    //
    values = {
      ...values,
      id: orderId,
      actualDeliveryLatitude:
        values.actualDeliveryCoordinates?.split(",")[0]?.trim() ?? null,
      actualDeliveryLongitude:
        values.actualDeliveryCoordinates?.split(",")[1]?.trim() ?? null,
    };
    try {
      const updatedOrder = await sendRequest(
        api.orders.processOrder(orderId, values)
      );
      toast.success(`Order has been started processing successfully`);
      successCallback(updatedOrder);

      onClose();
    } catch {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
