export const firebaseConfig = {
  // local: {
  //   apiKey: "AIzaSyAgHFmL6gL85cc7lAyyZL6zU79Va6-ThFw",
  //   authDomain: "batterio-dev.firebaseapp.com",
  //   projectId: "batterio-dev",
  //   storageBucket: "batterio-dev.appspot.com",
  //   messagingSenderId: "580498631619",
  //   appId: "1:580498631619:web:7be9a290a6556426762b76",
  //   measurementId: "G-MCRWQCGNPD",
  // },

  local: {
    apiKey: "AIzaSyDYMisSIQpNWx_NEqHNaAjkiO5A6QvSuAQ",
    authDomain: "batterio-dev-41237.firebaseapp.com",
    projectId: "batterio-dev-41237",
    storageBucket: "batterio-dev-41237.appspot.com",
    messagingSenderId: "991519193628",
    appId: "1:991519193628:android:bfa619475add86b9a3bdd9",
    measurementId: "G-MCRWQCGNPD",
  },
  staging: {
    apiKey: "AIzaSyD6zOP_YabuyBVGr1VLakYsgCf9MgcK8Pk",
    authDomain: "batterio-staging-e3e72.firebaseapp.com",
    projectId: "batterio-staging-e3e72",
    storageBucket: "batterio-staging-e3e72.appspot.com",
    messagingSenderId: "357554294174",
    appId: "1:357554294174:web:1ea40a1c7c324428835a8f",
  },
  production: {
    apiKey: "AIzaSyAfhU0z1jxmv1TXaG_xy0R_Qb_dbKDjW1E",
    authDomain: "batterio-prod.firebaseapp.com",
    projectId: "batterio-prod",
    storageBucket: "batterio-prod.appspot.com",
    messagingSenderId: "78623298966",
    appId: "1:78623298966:web:f545f87fb3ade63bd939b7",
  },
};
