export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
export const GOOGLE_MAP_SEARCH_BY_ADDRESS_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&address=`;
export const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
  },
  DEFAULT_CENTER: { lat: 30.005493, lng: 31.477898 },
  DEFAULT_ZOOM: 15,
  MARKER_HEIGHT: 45,
  PIXEL_OFFSET: {
    MARKER: {
      X: 0,
      Y: -42,
    },
  },
};
