import axios from "axios";
import { logInWithEmailAndPassword } from "./firebase";
import qs from "qs";
import { auth } from "./firebase";
import { saveAs } from "file-saver";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
      return;
    }
    prom.resolve(token);
  });
  failedQueue = [];
};

// const createAxiosResponseInterceptor = async () => {
// const interceptor =
axiosInstance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    /* Reject promise if usual error */
    if (!error.response || error.response?.status !== 401) {
      return Promise.reject(error);
    }
    let originalRequest = error.response.config;

    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((accessToken) => {
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    isRefreshing = true;
    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    // axiosInstance.interceptors.response.eject(interceptor);
    return new Promise(async function (resolve) {
      try {
        const newAccessToken = await auth.currentUser.getIdToken(true);
        setAuthToken(newAccessToken);
        error.response.config.headers.authorization = `Bearer ${newAccessToken}`;
        isRefreshing = false;
        processQueue(null, newAccessToken);
        resolve(axiosInstance(error.response.config));
      } catch (err) {
        isRefreshing = false;
        processQueue(error, null);
        resolve({ response: { status: 401 } });
      }
      // finally {
      //   return createAxiosResponseInterceptor;
      // }
    });
  }
);
// };

// createAxiosResponseInterceptor();

const setAuthToken = async (token) => {
  axiosInstance.defaults.headers.common.authorization = `Bearer ${token}`;
};

const clearAuthToken = async () => {
  return setAuthToken("");
};

const login = (authData) => {
  return logInWithEmailAndPassword(authData.id, authData.password);
  // return axiosInstance.post("auth/user/login", authData);
};

const refreshToken = () => axiosInstance.get("/auth/user/token/refresh");

const logout = () => axiosInstance.get("auth/user/logout");

const createFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key]?.length) {
      data[key].forEach((el, index) => {
        formData.append(`${key}[${index}]`, JSON.stringify(el));
      });
    } else {
      formData.append(
        key,
        typeof data[key] === "object" && data[key]?.arrayBuffer === undefined
          ? JSON.stringify(data[key])
          : data[key]
      );
    }
  });
  return formData;
};

const createDataResource = (path, isMultipart = false) => ({
  getAll: (
    page = undefined,
    limit = undefined,
    q = undefined,
    filters = undefined,
    orderBy = undefined
  ) => {
    let query = { page, limit, q, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`${path}?${query}`);
  },
  getOne: (id) => axiosInstance.get(`${path}/${id}`),
  create: (data) => {
    let response;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      response = axiosInstance.post(path, formData, config);
    } else {
      response = axiosInstance.post(path, data);
    }
    return response;
  },
  createMany: (data) => axiosInstance.post(`${path}/bulk`, data),
  patch: (data) => {
    let response;
    const id = data.id;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      response = axiosInstance.patch(`${path}/${id}`, formData, config);
    } else {
      response = axiosInstance.patch(`${path}/${id}`, data);
    }
    return response;
  },
  delete: (id) => axiosInstance.delete(`${path}/${id}`),
  updateMany: (data) => axiosInstance.patch(path, data),
});

const uploadFile = (file) => {
  const formData = createFormData({ file });

  for (var pair of formData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  return axiosInstance.post(`file-upload`, formData, config);
};

const uploadFiles = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === "files" && data[key].length > 0)
      data[key].forEach((file) => formData.append(`files`, file));
    else formData.append("files", data[key]);
  });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axiosInstance.post(`file-upload/bulk`, formData, config);
};

const users = {
  ...createDataResource("users"),
  fetchUserData: (firebaseID) =>
    axiosInstance.get(`users/firebase/${firebaseID}`),
};

const orders = {
  ...createDataResource("orders"),
  updateStatus: (orderId, status) =>
    axiosInstance.patch(`orders/${orderId}/status`, { status }),
  plan: (orderId, data) => axiosInstance.patch(`orders/${orderId}/plan`, data),
  lead: (orderId, data) => axiosInstance.post(`orders/${orderId}/lead`, data),
  complete: (orderId, data) =>
    axiosInstance.patch(`orders/${orderId}/complete`, data),
  assign: (orderId, data) =>
    axiosInstance.patch(`orders/${orderId}/assign`, data),
  cancel: (orderId, data) =>
    axiosInstance.patch(`orders/${orderId}/cancel`, data),
  lose: (orderId, data) => axiosInstance.patch(`orders/${orderId}/lose`, data),
  rate: (orderId, data) => axiosInstance.patch(`orders/${orderId}/rate`, data),
  getAllStockRelatedOrders: (page, limit, q, filters) => {
    let query = { page, limit, q, ...filters };
    query = qs.stringify(query);
    const response = axiosInstance.get(`orders/stock-related?${query}`);
    return response;
  },
  processOrder: (orderId, data) =>
    axiosInstance.post(`orders/${orderId}/process`, data),
  startOrder: (orderId, data) =>
    axiosInstance.post(`orders/${orderId}/start`, data),

  approveOrder: (orderId, data) =>
    axiosInstance.post(`orders/${orderId}/approve`, data),
  rejectOrder: (orderId) => axiosInstance.post(`orders/${orderId}/reject`),
  getAllOrderStockTransfer: (orderId) =>
    axiosInstance.get(`orders/${orderId}/stock-transfers/`),
  checkStockAvailability: (item, orderId) => {
    let query = { item, orderId };
    query = qs.stringify(query);
    const response = axiosInstance.get(
      `orders/check-stock-availability?${query}`
    );
    return response;
  },
  checkTruckStockAvailability: (orderId) =>
    axiosInstance.get(`orders/${orderId}/check-truck-availability`),
};

const stockItemsTransfers = {
  ...createDataResource("stock-items-transfers"),
  approveTransfer: (stockTransferId) =>
    axiosInstance.post(`stock-items-transfers/${stockTransferId}/approve`),
  rejectTransfer: (stockTransferId) =>
    axiosInstance.post(`stock-items-transfers/${stockTransferId}/reject`),
  uploadTransferSheet: (data) => {
    return axiosInstance.post(`stock-items-transfers/upload`, data);
  },
};

const customers = createDataResource("customers");
const cars = createDataResource("cars");
const carModels = {
  ...createDataResource("car-models"),
  getCarModelByMakerName: (makerName) =>
    axiosInstance.get(`car-models/models/${makerName}`),
  getCarYearsByModelName: (modelName) =>
    axiosInstance.get(`car-models/years/${modelName}`),
};
const orderServices = createDataResource("order-services");
const carMakers = createDataResource("car-makers");
const drivers = {
  ...createDataResource("drivers"),
  getDriverCurrentLocation: (driverId) =>
    axiosInstance.get(`drivers/driver-live-location/${driverId}`),
  getDriverLocationStream: (driverId) => {
    const token = axiosInstance.defaults.headers.common.authorization;
    return {
      url: `${axiosInstance.defaults.baseURL}drivers/driver-live-location/${driverId}/stream`,
      token: token,
    };
  },
};
const trucks = createDataResource("trucks");
const warranties = {
  ...createDataResource("warranties"),
  createBulk: (data) => axiosInstance.post(`warranties/create-bulk`, data),
  checkOnSerialNumber: (serialNumbers) =>
    axiosInstance.get(`warranties/checkOnSerialNumber`, {
      params: {
        serialNumbers: serialNumbers,
      },
    }),
  getOrderWarranties: (orderId) =>
    axiosInstance.get(`warranties/order/${orderId}`),
  createFromSheet: (data) => axiosInstance.post(`warranties/upload`, data),
};
const products = {
  ...createDataResource("products"),
  getStockStats: (
    page = undefined,
    limit = undefined,
    q = undefined,
    filters = undefined
  ) => {
    let query = { page, limit, q, ...filters };
    query = qs.stringify(query);
    return axiosInstance.get(`products/stock-stats?${query}`);
  },
  getProductAvailability: (productId, orderId) => {
    let query = { orderId };
    query = qs.stringify(query);
    const response = axiosInstance.get(
      `products/${productId}/availability?${query}`
    );
    return response;
  },
};
const stockItems = {
  ...createDataResource("stock-items"),
};
const invoices = createDataResource("invoices");

const driverShifts = {
  ...createDataResource("driver-shifts"),
  complete: (driverShiftId) =>
    axiosInstance.post(`driver-shifts/${driverShiftId}/complete`),
  getDriverShiftsTransfers: (
    page,
    limit,
    driverShiftId,
    driverShiftTransferType
  ) => {
    const filters = { driverShiftId, driverShiftTransferType };
    let query = { page, limit, ...filters };
    query = qs.stringify(query);
    return axiosInstance.get(
      `driver-shifts/${driverShiftId}/transfers?${query}`
    );
  },
  generateStartOfShiftReport: async (driverShiftId) => {
    const reportLink = await axiosInstance.post(
      `driver-shifts/${driverShiftId}/generate-start-of-shift-report`
    );
    return reportLink;
    // saveAs(reportLink.data);
  },
  generateEndOfShiftReport: async (driverShiftId) => {
    const reportLink = await axiosInstance.post(
      `driver-shifts/${driverShiftId}/generate-end-of-shift-report`
    );
    return reportLink;
  },
  generateInvoice: async (driverShiftId) => {
    const invoiceLink = await axiosInstance.post(
      `driver-shifts/${driverShiftId}/invoices`
    );
    return invoiceLink;
  },
  getInvoice: async (driverShiftId) => {
    const invoiceLink = await axiosInstance.get(
      `driver-shifts/${driverShiftId}/invoices`
    );
    return invoiceLink;
  },
  getReports: async (driverShiftId) => {
    const reports = await axiosInstance.get(
      `driver-shifts/${driverShiftId}/reports`
    );
    return reports;
  },
};

const scraps = createDataResource("scraps");
const manufacturers = createDataResource("manufacturers");
const cities = createDataResource("cities");
const districts = createDataResource("districts");
const countries = createDataResource("countries");
const zones = createDataResource("zones");
const orderItems = createDataResource("order-items");
const orderScrapItems = createDataResource("order-scrap-items");
const services = createDataResource("services");
const calls = createDataResource("calls");
const sizes = createDataResource("sizes");
const warehouses = createDataResource("warehouses");
const roles = createDataResource("roles");
const departments = createDataResource("departments");
const permissions = createDataResource("permissions");
const paymentMethods = createDataResource("payment-methods");
const truckTypes = createDataResource("truck-types");
const orderLossReasons = createDataResource("order-loss-reasons");
const orderCancellationReasons = createDataResource(
  "order-cancellation-reasons"
);
const promoCodes = {
  ...createDataResource("promo-codes"),
  check: (code) => axiosInstance.get(`promo-codes/check/${code}`),
};
const storages = {
  ...createDataResource("storages"),
  getStorageAvailability: (
    page = undefined,
    limit = undefined,
    q = undefined,
    filters = undefined,
    storageId
  ) => {
    let query = { page, limit, q, ...filters };
    query = qs.stringify(query);
    const response = axiosInstance.get(
      `storages/${storageId}/availability?${query}`
    );
    return response;
  },
};
const orderIssueTypes = createDataResource("order-issue-types");
const marketingSpend = createDataResource("marketing-spends");
const marketingSpendChannel = createDataResource("marketing-spend-channels");
const transferParty = createDataResource("transfer-party");
const stockTransfersSheets = createDataResource("stock-transfers-sheets");
const warrantiesSheets = createDataResource("warranties-sheets");

export default {
  users,
  customers,
  cars,
  carModels,
  carMakers,
  products,
  drivers,
  trucks,
  scraps,
  manufacturers,
  orders,
  cities,
  districts,
  countries,
  zones,
  orderItems,
  orderScrapItems,
  services,
  orderServices,
  calls,
  sizes,
  warehouses,
  roles,
  departments,
  permissions,
  paymentMethods,
  truckTypes,
  orderLossReasons,
  orderCancellationReasons,
  orderIssueTypes,
  promoCodes,
  marketingSpend,
  marketingSpendChannel,
  stockItems,
  transferParty,
  warranties,
  stockItemsTransfers,
  invoices,
  storages,
  stockTransfersSheets,
  warrantiesSheets,
  driverShifts,
  uploadFile,
  uploadFiles,
  login,
  refreshToken,
  setAuthToken,
  clearAuthToken,
  logout,
};
