import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "components/CustomButtons/Button.js";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Loading from "components/Loading/Loading";
import PropTypes from "prop-types";
import api from "services/api";
import { useDriversForm } from "./DriversForm.hook";
import styles from "./DriversForm.styles";
import React from "react";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DriversForm(props) {
  const { edit, create, driver } = props;

  const {
    isLoading,
    isGetLoading,
    form,
    data,
    watch,
    onSubmit,
  } = useDriversForm(props);

  const classes = useStyles();

  return (
    <div className={classes.formRoot}>
      <div className={classes.formHeader}>
        {create ? (
          <AddIcon className={classes.icon} />
        ) : (
          <EditIcon className={classes.icon} />
        )}
        {edit ? (
          isGetLoading ? (
            <Skeleton variant="rect" height={25} width={250} />
          ) : (
            `Edit ${driver?.name}`
          )
        ) : (
          `Create Driver`
        )}
      </div>

      <Loading loading={isGetLoading} style={{ height: "60vh" }}>
        <form
          style={{ padding: 40, paddingBottom: 0, paddingTop: 0 }}
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormField
                name="name"
                md={12}
                fetchedName="name"
                type="text"
                label="Name"
                form={form}
              />

              <FormField
                name="phone"
                md={12}
                fetchedName="phone"
                type="text"
                label="Phone"
                form={form}
              />
              <FormField
                name="nationalId"
                md={12}
                fetchedName="nationalId"
                type="text"
                label="National ID"
                form={form}
              />
              <FormField
                name="password"
                type="text"
                label="Password"
                form={form}
              />
              <FormField
                md={12}
                name="drivingLicenseDocumentUrl"
                type="dropzone"
                label="Driving License Document"
                options={{ maxFiles: 1 }}
                form={form}
              />

              <FormField
                name="drivingLicenseExpirationDate"
                type="date"
                label="License Expiration Date"
                form={form}
              />
              <FormField
                name="joiningDate"
                type="date"
                label="Joining Date"
                form={form}
              />

              <FormField
                name="leaveDate"
                type="date"
                label="Leave Date"
                form={form}
              />
              <FormField
                name="status"
                type="select"
                label="Status"
                form={form}
                options={{
                  enum: "driverStatus",
                }}
              />

              <FormField
                name="defaultTruck"
                fetchedName="defaultTruckId"
                type="autocomplete"
                label="Default Truck"
                form={form}
                options={{
                  getData: api.trucks.getAll,
                  getOptionLabel: (option) => option.truckNumber,
                  getSubmitValue: (option) => option?.id,
                  loadOnFocus: true,
                  blurOnSelect: false,
                  freeSolo: true,
                  dataResource: api.trucks,
                  blurOnSelect: false,
                  loadOnFocus: true,
                }}
              />
            </GridItem>
          </GridContainer>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              type="submit"
              loading={isLoading}
              style={{ margin: "20px 0" }}
              create={create}
              edit={edit}
              loadFromRight
            />
          </div>
        </form>
      </Loading>
    </div>
  );
}

DriversForm.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};
